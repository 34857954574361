import React, {useEffect, useState} from "react";
import logo from './images/Renders/Others/Tonkar.png';
import './App.css';
import {createTheme, Divider, Stack, ThemeProvider} from "@mui/material";
import {Header} from "./View/Header/Header";
import Grid2 from "@mui/material/Unstable_Grid2";
import {MediaContent} from "./View/Illustration/MediaContent";
import {DashboardTabs} from "./View/Header/DashboardTabs";
import {CookingRecipeGrid} from "./View/CookingRecipes/CookingRecipeGrid";
import {generateTonalTheme} from "./Style/theme";
import {TonalPalette, TonalPaletteBase} from "./Style/tonalPalette";
import {BaseTheme} from "./Style/BaseTheme";
import {GameContent} from "./View/Games/GameContent";

const DarkTheme = (palette: TonalPalette) =>
  createTheme(BaseTheme, generateTonalTheme(palette, "dark"));

const LightTheme = (palette: TonalPalette) =>
  createTheme(BaseTheme, generateTonalTheme(palette, "light"));

function App() {
  const palette = TonalPalette.fromColor("#a5c8ff");

  const [darkMode, setDarkMode] = useState<boolean>(true);

  const darkTheme = DarkTheme(palette);
  const lightTheme = LightTheme(palette);

  const theme = darkMode ? darkTheme : lightTheme;

  useEffect(() => {
    // @ts-ignore
    document.body.style.backgroundColor = theme.palette.background.default;
  }, [theme.palette.background.default])

  return (
    <ThemeProvider theme={theme} >
      <div className={"App"} style={{
        backgroundColor: theme.palette.background.default
      }}>
        <Stack
          sx={{
            "*": { transition: "background-color 300ms linear, color 300ms linear" }
          }}
          direction={'column'} justifyContent={'center'} p={0}
        >
          <Header
            darkMode={darkMode}
            onDarkModeChange={setDarkMode}
          />

          <Divider orientation={'horizontal'}/>

          <DashboardTabs>
            {[
              {tabName: 'Illustrations', tab: <MediaContent/>},
              {tabName: 'Games', tab: <GameContent/>},
              /* {tabName: 'Cooking Recipes', tab: <CookingRecipeGrid/>}, */
            ]}
          </DashboardTabs>
        </Stack>
      </div>
    </ThemeProvider>
  );
}

export default App;
