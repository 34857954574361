import {Box, Card, CardActionArea, CardContent, CardMedia, Grid, Link, Typography, useTheme} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";

export type Image = { image: string, transparent?: boolean };

export const MediaContentItems = (props: {
	title: string | { label: string, url: string }, images: Image[]
}) => {

	return <>{props.images.map((image, i) =>
		<MediaContentItem key={i} image={image} title={props.title}/>,
	)}</>;
};

function MediaContentItem(props: {
	title: string | { label: string, url: string }, image: Image
}) {
	const htmlRef = useRef<HTMLDivElement>(null);

	const [width, setWidth] = useState(1);

	const imageEl = useRef<HTMLImageElement>(null);

	useEffect(() => {
		if (imageEl.current !== null) {
			const observer = new ResizeObserver(([e]) => setWidth(e.target.clientWidth))
			observer.observe(imageEl.current);

			return () => observer.disconnect();
		}
	}, [imageEl.current?.height]);

	const theme = useTheme();

	const label = (
		typeof props.title === "string"
			? props.title
			: props.title.label
	);
	const url = (typeof props.title === "string" ? "" : props.title.url);

	return <Grid
		item xs={width !== undefined ? Math.ceil(width / 300) : 1}
		style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}
	>
		<Box ref={htmlRef} sx={{
			backgroundImage: "none", width: "100%", flexGrow: 1,
			display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
		}}>
			<Card color={"primary"} style={{ width: "fit-content" }}>
				<CardActionArea href={url} target={"_blank"} rel={"noopener noreferrer"}>
					<CardContent
						style={{
							display: "flex", flexDirection: "column",
							alignItems: "center", justifyContent: "center",
							gap: 2,
						}}
					>
						<Link underline={"none"} >
							<Typography variant={"h5"} sx={{
								textDecoration: `underline solid 2px ${theme.palette.primary.main}`,
							}}>
								{label}
							</Typography>
						</Link>
					</CardContent>
					<CardMedia
						component={"img"}
						src={props.image.image}
						style={{
							width: "auto", height: 300,
						}}
						ref={imageEl}
					/>
				</CardActionArea>
			</Card>
		</Box>
	</Grid>;
}