import {type ThemeTones, TonalPalette} from "./tonalPalette";
import {type CSSProperties} from "react";
import {withAlpha} from "./colorUtils";

const BASE_SUCCESS_COLOR = "#66bb6a";

export function generateTonalTheme(
	palette: TonalPalette,
	mode: "dark" | "light",
) {
	const isDarkMode = mode === "dark";

	const moddedPalette = palette.get(mode);

	return {
		palette: {
			mode: mode,
			primary: moddedPalette.primary,
			primaryContainer: moddedPalette.primaryContainer,

			secondary: moddedPalette.secondary,
			secondaryContainer: moddedPalette.secondaryContainer,

			info: moddedPalette.tertiary,
			infoContainer: moddedPalette.tertiaryContainer,

			error: moddedPalette.error,
			errorContainer: moddedPalette.errorContainer,

			success: moddedPalette.customColor(BASE_SUCCESS_COLOR, true).base,
			successContainer: moddedPalette.customColor(BASE_SUCCESS_COLOR, true).container,

			background: {
				default: moddedPalette.background.main,
				paper: undefined,
			},
			text: {
				primary: TonalPalette.luminanceScale(moddedPalette.background.contrastText!, isDarkMode ? 90 : 10),
				secondary: TonalPalette.luminanceScale(moddedPalette.background.contrastText!, isDarkMode ? 70 : 30),
				disabled: withAlpha(TonalPalette.luminanceScale(moddedPalette.background.contrastText!, 0), 0.38),
			},
			outline: moddedPalette.outline.main,
			outlineVariant: moddedPalette.outlineVariant.main,
			surfaceVariant: moddedPalette.surfaceVariant,
			surface: moddedPalette.surface,
			inverseSurface: moddedPalette.inverseSurface,
			inversePrimary: moddedPalette.inversePrimary.main,
			customColors: moddedPalette.customColors,

			customColor: function<TTone extends keyof ThemeTones> (
				color: Exclude<CSSProperties["color"], undefined>,
				blend: boolean,
				type: TTone,
				transparent?: boolean,
			): ThemeTones[TTone] {
				return palette[mode].customColor(color, blend, transparent)[type];
			},
		},
	};
}