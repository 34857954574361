import {Box, Card, CardActionArea, CardContent, CardMedia, Chip, Grid, Link, Typography, useTheme} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";

export default function GameContentItem(props: {
	url: string;
	title: string;
	imageUrl: string;
	description: string;
	genre?: string;
	platforms: string[];
}) {
	const {
		url, title, imageUrl, description, genre, platforms,
	} = props;

	const htmlRef = useRef<HTMLDivElement>(null);

	const [width, setWidth] = useState(1);

	const imageEl = useRef<HTMLImageElement>(null);

	useEffect(() => {
		if (imageEl.current !== null) {
			const observer = new ResizeObserver(([e]) => setWidth(e.target.clientWidth))
			observer.observe(imageEl.current);

			return () => observer.disconnect();
		}
	}, [imageEl.current?.height]);

	const theme = useTheme();

	console.log(width);

	return <Grid
		item xs={1}
		style={{
			display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
			boxSizing: "border-box",
		}}
	>
		<Box ref={htmlRef} sx={{
			backgroundImage: "none", width: "100%", flexGrow: 1,
			display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
			boxSizing: "border-box",
		}}>
			<Card color={"primary"} style={{width: "fit-content"}}>
				<CardActionArea href={url} target={"_blank"} rel={"noopener noreferrer"}>
					<CardMedia
						component={"img"}
						src={imageUrl}
						style={{width: 400, height: 300}}

						ref={imageEl}
					/>
					<CardContent
						style={{
							display: "flex", flexDirection: "column",
							alignItems: "center", justifyContent: "center",
							gap: 2,
							maxWidth: width ?? 250,
							boxSizing: "border-box",
						}}
					>
						<Link underline={"none"} rel={"noopener noreferrer"}>
							<Typography variant={"h5"} sx={{
								textDecoration: `underline solid 2px ${theme.palette.primary.main}`,
							}}>
								{title}
							</Typography>
						</Link>
						<Box sx={{
							display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start",
						}}>
							<Typography variant={"body1"} color={"text.primary"} textAlign={"left"}>
								{description}
							</Typography>
							<Typography variant={"caption"} color={"text.secondary"} textAlign={"left"}>
								{genre}
							</Typography>
							<Box sx={{
								marginTop: 1,
								display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start",
								gap: 1,
							}}>
								{platforms.map((platform, i) =>
									<Chip key={i} label={platform} size={"small"} variant={"outlined"}/>
								)}
							</Box>
						</Box>
					</CardContent>
				</CardActionArea>
			</Card>
		</Box>
	</Grid>;
}