import {createTheme, Palette, ThemeOptions} from "@mui/material";
import {PaletteColor} from "./tonalPalette";
import {hexToRgb} from "./colorUtils";

export const BACKGROUND_TRANSPARENCY = 0.05;

// @ts-ignore
export const BaseTheme = createTheme({
	borderRadius: {
		small: "8px",
		medium: "12px",
		large: "16px",
		extraLarge: "28px",
		full: "50%",
	},
	padding: {
		small: "8px",
		medium: "24px",
		large: "40px",
	},
	backgroundTransparency: BACKGROUND_TRANSPARENCY,
	components: {
		MuiButton: {
			defaultProps: {
				variant: "contained",
				color: "primary",
			},
			styleOverrides: {
				root: ({theme, ownerState}) => ({
					...(ownerState.variant === "outlined" && {
						borderColor: theme.palette.outline,
					}),
					...(ownerState.variant === "text" && {
						fontWeight: "bold",
					}),
					"&:hover": {
						...(ownerState.color === "primary" && {backgroundColor: theme.palette.primaryContainer}),
						...(ownerState.color === "secondary" && {backgroundColor: theme.palette.secondaryContainer}),
						...(ownerState.color === "info" && {backgroundColor: theme.palette.infoContainer}),
						...(ownerState.color === "error" && {backgroundColor: theme.palette.errorContainer}),
					},
					borderRadius: theme.borderRadius.extraLarge,
					padding: "0 24px",
					...(ownerState.size === "medium" && {height: "40px"}),
					...(ownerState.size === "small" && {height: "28px"}),
					fontFamily: "\"Google Sans Text\", sans-serif",
				}),
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: ({theme, ownerState}) => ({
					...(ownerState.variant === "outlined" && {
						borderColor: theme.palette.outline,
					}),
				}),
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {color: "inherit"},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {color: "inherit"},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: ({theme, ownerState}) => ({
					...(ownerState.variant === "outlined" && {
						borderColor: theme.palette.outline,
					}),
				}),
			},
		},
		MuiPaper: {
			defaultProps: {color: "primary", elevation: 1},
			styleOverrides: {
				root: ({theme, ownerState}) => {

					const colorIndex = ownerState.color as keyof Palette;
					// TODO Correct
					const colorContainerIndex = theme.palette.background.paper as keyof Palette;

					const elevationsValues = [0.05, 0.08, 0.11, 0.12, 0.14];

					if (ownerState.elevation === undefined) {
						throw new Error("Elevation is undefined !");
					}

					let elevationIndex = ownerState.elevation - 1;
					let elevationValue;

					if (elevationIndex < 0) {
						throw new Error(`Unsupported elevation: ${ownerState.elevation}`);
					} else if (elevationIndex > elevationsValues.length - 1) {
						elevationValue = 0.2;
					} else {
						elevationValue = elevationsValues[elevationIndex];
					}

					const primaryHex = (theme.palette[colorIndex] as PaletteColor).main as string;
					const {r: pR, g: pG, b: pB} = hexToRgb(primaryHex);

					const rgbValue = `rgb(${pR} ${pG} ${pB} / ${elevationValue})`;

					const menuBackgroundColor = theme.palette.background.default;
					const menuColor = theme.palette.text.primary;

					const ignoredClassNames = [
						".MuiMenu-paper",
						".MuiAppBar-root",
						".MuiCard-root",
						".MuiDialog-paper",
						".MuiPopover-paper",
						".MuiPickersPopper-paper",
						".MuiAutocomplete-paper",
					];

					return (
						{
							[`&:not(${ignoredClassNames.join(", ")})`]: {
								borderRadius: theme.borderRadius.medium,
								backgroundColor: rgbValue,
								backgroundImage: "none",
								color: theme.palette.surface.contrastText,
								boxShadow: "none",
							},
							padding: 0,
							backgroundColor: menuBackgroundColor,
							backgroundImage: "none",
							color: menuColor,
						});
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				paper: ({theme}) => ({
					padding: 0,
					borderRadius: theme.borderRadius.small,
				}),
			},
		},
		MuiCard: {
			styleOverrides: {
				root: ({theme}) => ({
					borderRadius: theme.borderRadius.medium,
				}),
			},
		},
		MuiAppBar: {
			// @ts-ignore
			defaultProps: {color: "primaryContainer", enableColorOnDark: false},
			styleOverrides: {
				root: {position: "sticky"},
			},
		},
		MuiIconButton: {defaultProps: {color: "primary"}},
		MuiDivider: {
			styleOverrides: {
				root: ({theme}) => ({
					borderColor: theme.palette.outline,
				}),
			},
		},
		MuiTab: {
			styleOverrides: {
				root: ({ownerState}) => ({
					...(!ownerState.label ? {
						minWidth: "inherit",
						aspectRatio: "1/1",
					} : {}),
					"& > .MuiTouchRipple-root": {
						borderRadius: 999,
						left: 0,
						right: 0,
						...(!ownerState.label ? {
							top: 0,
							bottom: 0,
						} : {
							top: 9,
							bottom: 9,
						}),
					},
				}),
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: ({theme, ownerState}) => ({
					"&  .MuiTabs-indicator": {
						backgroundColor: "transparent",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",

						"&:after": {
							content: "\"\"",
							display: "block",
							width: ownerState.orientation === "horizontal" ? "40%" : "100%",
							height: ownerState.orientation === "horizontal" ? "100%" : "40%",
							// @ts-ignore
							backgroundColor: theme.palette[ownerState.indicatorColor]!.main,
							borderTopLeftRadius: theme.borderRadius.extraLarge,
							borderTopRightRadius: theme.borderRadius.extraLarge,
						},
					},
				}),
			},
		},
		MuiBadge: {
			defaultProps: {color: "primary"},
		},
	},
	typography: {
		fontFamily: "'Zilla Slab', serif",
	},
}) as ThemeOptions;