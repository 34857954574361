import {Grid, Link, Stack, Typography} from "@mui/material";
import React from "react";
import GameContentItem from "./GameContentItem";

export function GameContent() {


	return <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} paddingTop={0}>
		<Link href="https://wollfie.itch.io" target="_blank" rel="noopener noreferrer" underline="none">
			<Typography variant={"h4"} sx={{textDecoration: "underline"}}>
				Itch.io Page
			</Typography>
		</Link>
		<Grid
			container spacing={2}
			alignItems={"start"} justifyContent={"center"}
			paddingRight={"10vw"} paddingLeft={"10vw"}
			margin={0} columns={{xs: 1, md: 1, lg: 3}}
		>
			<GameContentItem
				url={"https://wollfie.itch.io/stronghold-of-dusk"}
				title={"Stronghold of Dusk"}
				description={"Defend your castle against the monsters' attacks in VR!"}
				genre={"Action"}
				platforms={["Android"]}
				imageUrl={"https://img.itch.zone/aW1nLzE2NTI2OTQyLnBuZw==/315x250%23c/ZcrZXP.png"}
			/>
			<GameContentItem
				url={"https://jucifer06.itch.io/shes-sleeping"}
				title={"She's sleeping..."}
				description={"Don't wake her up... Escape the Giant Spider's Gaze"}
				genre={"Platformer"}
				platforms={["Web", "Windows", "MacOS"]}
				imageUrl={"https://img.itch.zone/aW1nLzE1MzI4ODM3LnBuZw==/315x250%23c/H6xtUg.png"}
			/>
			<GameContentItem
				url={"https://wollfie.itch.io/the-unforgettable-adventure-of-cutie"}
				title={"The Unforgettable Adventure of Cutie"}
				description={"Dive into the adventure of cutie, a cute orange fur ball who happen to love planes and unforgettable journeys !"}
				genre={"Platformer"}
				platforms={["Windows"]}
				imageUrl={"https://img.itch.zone/aW1nLzk0MTg4NDUucG5n/315x250%23c/VhW%2Bjb.png"}
			/>
			<GameContentItem
				url={"https://rickpooljohn.itch.io/a-journey-in-chaos"}
				title={"A Journey in Chaos"}
				description={"As the Dragon incarnation, will you be able to accomplish your duty towards the Universe?"}
				genre={"Adventure"}
				platforms={["Windows"]}
				imageUrl={"https://img.itch.zone/aW1nLzkxNTg3MTAuanBn/315x250%23c/V5dvYd.jpg"}
			/>
		</Grid>
	</Stack>;
}