import {AppBar, IconButton, Stack, Typography, useTheme} from "@mui/material";

import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoon, faSun} from "@fortawesome/free-solid-svg-icons";
import {NameHeader} from "./NameHeader";
import {PricingButton} from "./PricingButton";
import {ContactButton} from "./ContactButton";
import {Pricing} from "./Pricing";
import PlaylistButton from "../../images/Music/PlaylistButton";

export const Header = (props: {
	onDarkModeChange: (darkMode: boolean) => void,
	darkMode: boolean
}) => {

	const [pricingDialogOpened, setPricingDialogOpened] = useState(false);

	const showPricingDialog = () => setPricingDialogOpened(true);

	const handleClose = () => setPricingDialogOpened(false);

	const commissionsOpen = true;

	const theme = useTheme();

	return (
		<AppBar
			style={{
				display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center",
			}}
		>
			<Stack
				width={"100%"}
				height={"100%"}
				direction={{[theme.breakpoints.down("md")]: "column", md: "row"}}
				justifyContent={"space-between"}
				alignItems={"center"}
				paddingX={{ [theme.breakpoints.down("md")]: 0, md: 5 }}
				paddingY={{ [theme.breakpoints.down("md")]: 5, md: 0 }}
			>
				<NameHeader/>

				<Stack
					direction={{[theme.breakpoints.down("md")]: "column", md: "row"}}
					justifyContent={{[theme.breakpoints.down("md")]: "center", md: "end"}}
					alignItems={"center"}
					width={{[theme.breakpoints.down("md")]: "100%", md: "50%"}}
					spacing={4} gap={2} alignContent={"center"}
				>
					<Typography variant={"h6"}>
						{"Commissions: "}
						<Typography variant={"h6"} component={"span"} style={{
							color: commissionsOpen ? theme.palette.success.main : theme.palette.error.main,
						}}>
							{commissionsOpen ? "Open" : "Closed"}
						</Typography>
					</Typography>
					<IconButton
						style={{margin: 0}}
						onClick={() => props.onDarkModeChange(!props.darkMode)}
						color={"primary"}
					>
						{props.darkMode ? <FontAwesomeIcon icon={faSun}/> : <FontAwesomeIcon icon={faMoon}/>}
					</IconButton>
					<PlaylistButton/>
					<PricingButton showPricingDialog={showPricingDialog}/>
					<ContactButton/>
				</Stack>

				<Pricing open={pricingDialogOpened} onClose={handleClose}/>
			</Stack>
		</AppBar>
	);
};