import {
	differenceDegrees,
	rotationDirection,
	sanitizeDegreesDouble,
} from "./mathUtils";
import {type CSSProperties} from "react";
import {type CustomColorsType} from "./palettes";
import {
	hexToRgb,
	hslToRgb,
	rgbDictToHex,
	rgbDictToHsl,
	rgbToHex,
	rgbToHsl,
	withAlpha,
} from "./colorUtils";

export interface PaletteColor {
	main: Exclude<CSSProperties["color"], undefined>;
	light?: Exclude<CSSProperties["color"], undefined>;
	dark?: Exclude<CSSProperties["color"], undefined>;
	contrastText?: Exclude<CSSProperties["color"], undefined>;
}

export interface ThemeTones {
	base: PaletteColor;
	container: PaletteColor;
}

const TRANSPARENCY_COLOR_TO_CONTAINER_RATIO = 0.1;
const SHADE_SHIFT = 10;

export class TonalPalette {

	private static BlendingFactor = .5;

	light: LightTonalPalette;
	dark: DarkTonalPalette;

	private constructor(light: LightTonalPalette, darK: DarkTonalPalette) {
		this.light = light;
		this.dark = darK;
	}

	get(mode: "dark"|"light") {
		return mode === "dark" ? this.dark : this.light;
	}

	static fromColors(
		primary: string,
		secondary: string,
		tertiary: string,
		error: string,
		neutral: string,
		neutralVariant: string,
		customColors: CustomColorsType = {},
		source: string = primary,
	) {
		return new TonalPalette(
			new LightTonalPalette(primary, secondary, tertiary, error, neutral, neutralVariant, (customColors || {}), source),
			new DarkTonalPalette(primary, secondary, tertiary, error, neutral, neutralVariant, (customColors || {}), source),
		);
	}

	static fromColor(
		color: string,
		customColors?: CustomColorsType,
		isContent: boolean = false,
	): TonalPalette {
		const {r: r, g: g, b: b} = hexToRgb(color);
		const {h, s, l} = rgbToHsl(r, g, b);

		let primary, secondary, tertiary, error, neutral, neutralVariant;

		if (isContent) {
			primary = rgbDictToHex(hslToRgb(h, s, l));
			secondary = rgbDictToHex(hslToRgb(h, s / 3, l));
			tertiary = rgbDictToHex(hslToRgb(h + 60 / 360, s / 2, l));
			error = rgbDictToHex(hslToRgb(25 / 360, .84, l));
			neutral = rgbDictToHex(hslToRgb(h, Math.min(s / 12, .04), l));
			neutralVariant = rgbDictToHex(hslToRgb(h, Math.min(s / 6, .08), l));
		} else {
			primary = rgbDictToHex(hslToRgb(h, Math.max(0.48, s), l));
			secondary = rgbDictToHex(hslToRgb(h, 0.16, l));
			tertiary = rgbDictToHex(hslToRgb(h + 60 / 360, 0.24, l));
			error = rgbDictToHex(hslToRgb(25 / 360, 84 / 100, l));
			neutral = rgbDictToHex(hslToRgb(h, 0.04, l));
			neutralVariant = rgbDictToHex(hslToRgb(h, 0.08, l));
		}

		return new TonalPalette(
			new LightTonalPalette(primary, secondary, tertiary, error, neutral, neutralVariant, (customColors || {}), color),
			new DarkTonalPalette(primary, secondary, tertiary, error, neutral, neutralVariant, (customColors || {}), color),
		);
	}

	static luminanceScale(base: string, value: number) {
		const luminance = value;
		const {r: r0, g: g0, b: b0} = hexToRgb(base);
		const {h, s} = rgbToHsl(r0, g0, b0);
		const {r: rF, g: gF, b: bF} = hslToRgb(h, s, luminance / 100.0);

		return rgbToHex(rF, gF, bF);
	}

	static harmonize(from: string, to: string): string {
		const fromHsl = rgbDictToHsl(hexToRgb(from));
		const toHsl = rgbDictToHsl(hexToRgb(to));

		const fromHue = fromHsl.h * 360;
		const toHue = toHsl.h * 360;

		const diffDeg = differenceDegrees(fromHue, toHue);
		const rotDeg = Math.min(diffDeg * TonalPalette.BlendingFactor, 15.0);

		const outputHue = sanitizeDegreesDouble(
			fromHue
			+ rotDeg * rotationDirection(fromHue, toHue),
		);
		return rgbDictToHex(hslToRgb(outputHue / 360, fromHsl.s, fromHsl.l));
	}

	static generateLightTones(color: string, container: boolean): PaletteColor {
		return container ? this.getLightContainer(color) : this.getLightBase(color);
	}

	static generateDarkTones(color: string, container: boolean): PaletteColor {
		return container ? this.getDarkContainer(color) : this.getDarkBase(color);
	}

	static getDarkBase(color: string): PaletteColor {
		return {
			light: TonalPalette.luminanceScale(color, 80 + SHADE_SHIFT),
			main: TonalPalette.luminanceScale(color, 80),
			dark: TonalPalette.luminanceScale(color, 80 - SHADE_SHIFT),
			contrastText: TonalPalette.luminanceScale(color, 20),
		};
	}

	static getDarkContainer(color: string): PaletteColor {
		return {
			light: TonalPalette.luminanceScale(color, 30 + SHADE_SHIFT),
			main: TonalPalette.luminanceScale(color, 30),
			dark: TonalPalette.luminanceScale(color, 30 - SHADE_SHIFT),
			contrastText: TonalPalette.luminanceScale(color, 90),
		};
	}

	static getLightBase(color: string): PaletteColor {
		return {
			light: TonalPalette.luminanceScale(color, 40 + SHADE_SHIFT),
			main: TonalPalette.luminanceScale(color, 40),
			dark: TonalPalette.luminanceScale(color, 40 - SHADE_SHIFT),
			contrastText: TonalPalette.luminanceScale(color, 100),
		};
	}

	static getLightContainer(color: string): PaletteColor {
		return {
			light: TonalPalette.luminanceScale(color, 90 + SHADE_SHIFT),
			main: TonalPalette.luminanceScale(color, 90),
			dark: TonalPalette.luminanceScale(color, 90 - SHADE_SHIFT),
			contrastText: TonalPalette.luminanceScale(color, 10),
		};
	}

	static withTransparency(color: PaletteColor, alpha: number): PaletteColor {
		return {
			light: color.light !== undefined ? withAlpha(color.light, alpha) : undefined,
			main: withAlpha(color.main, alpha),
			dark: color.dark !== undefined ? withAlpha(color.dark, alpha) : undefined,
			...(color.contrastText === undefined ? {} : {contrastText: withAlpha(color.contrastText, alpha)}),
		};
	}
}

export abstract class TonalPaletteBase {

	source: string;

	abstract primary: PaletteColor;
	abstract primaryContainer: PaletteColor;
	abstract secondary: PaletteColor;
	abstract secondaryContainer: PaletteColor;
	abstract tertiary: PaletteColor;
	abstract tertiaryContainer: PaletteColor;
	abstract error: PaletteColor;
	abstract errorContainer: PaletteColor;
	abstract background: PaletteColor;
	abstract surface: PaletteColor;
	abstract surfaceVariant: PaletteColor;
	abstract outline: PaletteColor;
	abstract outlineVariant: PaletteColor;
	abstract shadow: PaletteColor;
	abstract scrim: PaletteColor;
	abstract inverseSurface: PaletteColor;
	abstract inversePrimary: PaletteColor;
	dark: boolean;

	customColors: { [k in keyof CustomColorsType]: ThemeTones };

	protected constructor(
		source: string,
		customColors: CustomColorsType = {},
		dark: boolean,
	) {
		this.dark = dark;
		this.source = source;
		this.customColors = Object.fromEntries(Object.entries(customColors).map(
			([colorKey, colorValue]) => {
				return [colorKey, this.customColor(colorValue, true)];
			},
		));
	}

	customColor(
		customColor: string,
		blend: boolean,
		transparent: boolean = false,
	): ThemeTones {
		let color = customColor;

		if (blend) {
			color = TonalPalette.harmonize(customColor, this.source);
		}

		const palette = TonalPalette.fromColor(color, {}, false);

		const mode = this.dark ? "dark" : "light";

		if (!transparent) {
			return {base: palette[mode].primary, container: palette[mode].primaryContainer};
		} else {
			return {
				base: palette[mode].primary,
				container: TonalPalette.withTransparency(
					palette[mode].primaryContainer,
					TRANSPARENCY_COLOR_TO_CONTAINER_RATIO,
				),
			};
		}
	}

}

export class LightTonalPalette extends TonalPaletteBase {

	primary: PaletteColor;
	primaryContainer: PaletteColor;
	secondary: PaletteColor;
	secondaryContainer: PaletteColor;
	tertiary: PaletteColor;
	tertiaryContainer: PaletteColor;
	error: PaletteColor;
	errorContainer: PaletteColor;
	background: PaletteColor;
	surface: PaletteColor;
	surfaceVariant: PaletteColor;
	outline: PaletteColor;
	outlineVariant: PaletteColor;
	scrim: PaletteColor;
	shadow: PaletteColor;
	inversePrimary: PaletteColor;
	inverseSurface: PaletteColor;

	constructor(
		primary: string,
		secondary: string,
		tertiary: string,
		error: string,
		neutral: string,
		neutralVariant: string,
		customColors: CustomColorsType = {},
		source: string = primary,
	) {
		super(source, customColors, false);
		this.primary = TonalPalette.generateLightTones(primary, false);
		this.primaryContainer = TonalPalette.generateLightTones(primary, true);
		this.secondary = TonalPalette.generateLightTones(secondary, false);
		this.secondaryContainer = TonalPalette.generateLightTones(secondary, true);
		this.tertiary = TonalPalette.generateLightTones(tertiary, false);
		this.tertiaryContainer = TonalPalette.generateLightTones(tertiary, true);
		this.error = TonalPalette.generateLightTones(error, false);
		this.errorContainer = TonalPalette.generateLightTones(error, true);
		this.background = {
			main: TonalPalette.luminanceScale(neutral, 99),
			contrastText: TonalPalette.luminanceScale(neutral, 10),
		};
		this.surface = {
			main: TonalPalette.luminanceScale(neutral, 99),
			contrastText: TonalPalette.luminanceScale(neutral, 10),
		};
		this.surfaceVariant = {
			main: TonalPalette.luminanceScale(neutralVariant, 90),
			contrastText: TonalPalette.luminanceScale(neutralVariant, 30),
		};
		this.outline = {main: TonalPalette.luminanceScale(neutralVariant, 50)};
		this.outlineVariant = {main: TonalPalette.luminanceScale(neutralVariant, 80)};
		this.shadow = {main: TonalPalette.luminanceScale(neutralVariant, 0)};
		this.scrim = {main: TonalPalette.luminanceScale(neutralVariant, 0)};
		this.inverseSurface = {
			main: TonalPalette.luminanceScale(neutral, 20),
			contrastText: TonalPalette.luminanceScale(neutral, 95),
		};
		this.inversePrimary = {main: TonalPalette.luminanceScale(neutralVariant, 80)};
	}

}

export class DarkTonalPalette extends TonalPaletteBase {

	primary: PaletteColor;
	primaryContainer: PaletteColor;
	secondary: PaletteColor;
	secondaryContainer: PaletteColor;
	tertiary: PaletteColor;
	tertiaryContainer: PaletteColor;
	error: PaletteColor;
	errorContainer: PaletteColor;
	background: PaletteColor;
	surface: PaletteColor;
	surfaceVariant: PaletteColor;
	outline: PaletteColor;
	outlineVariant: PaletteColor;
	scrim: PaletteColor;
	shadow: PaletteColor;
	inversePrimary: PaletteColor;
	inverseSurface: PaletteColor;

	constructor(
		primary: string,
		secondary: string,
		tertiary: string,
		error: string,
		neutral: string,
		neutralVariant: string,
		customColors: CustomColorsType = {},
		source: string = primary,
	) {
		super(source, customColors, true);
		this.primary = TonalPalette.generateDarkTones(primary, false);
		this.primaryContainer = TonalPalette.generateDarkTones(primary, true);
		this.secondary = TonalPalette.generateDarkTones(secondary, false);
		this.secondaryContainer = TonalPalette.generateDarkTones(secondary, true);
		this.tertiary = TonalPalette.generateDarkTones(tertiary, false);
		this.tertiaryContainer = TonalPalette.generateDarkTones(tertiary, true);
		this.error = TonalPalette.generateDarkTones(error, false);
		this.errorContainer = TonalPalette.generateDarkTones(error, true);
		this.background = {
			main: TonalPalette.luminanceScale(neutral, 10),
			contrastText: TonalPalette.luminanceScale(neutral, 90),
		};
		this.surface = {
			main: TonalPalette.luminanceScale(neutral, 10),
			contrastText: TonalPalette.luminanceScale(neutral, 90),
		};
		this.surfaceVariant = {
			main: TonalPalette.luminanceScale(neutralVariant, 30),
			contrastText: TonalPalette.luminanceScale(neutralVariant, 80),
		};
		this.outline = {main: TonalPalette.luminanceScale(neutralVariant, 60)};
		this.outlineVariant = {main: TonalPalette.luminanceScale(neutralVariant, 30)};
		this.shadow = {main: TonalPalette.luminanceScale(neutralVariant, 0)};
		this.scrim = {main: TonalPalette.luminanceScale(neutralVariant, 0)};
		this.inverseSurface = {
			main: TonalPalette.luminanceScale(neutral, 90),
			contrastText: TonalPalette.luminanceScale(neutral, 20),
		};
		this.inversePrimary = {main: TonalPalette.luminanceScale(neutralVariant, 40)};
	}

}